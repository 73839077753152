@use 'sass:color';
@use '../functions' as *;

// Overrides of default vars

$blurple-600: #a71494;
$blurple-500: #d61ebe;
$blurple-300: #ef6ede;

$classic-base-color: #190e25;
$classic-primary-color: #d4b6cb;
$classic-secondary-color: #ffe8fc;

// Homogay variables

$ui-base-darker-color: darken($classic-base-color, 5%) !default;
$ui-base-semi-lighter-color: lighten($classic-base-color, 3%) !default;
$ui-base-extra-light-color: lighten($classic-base-color, 18%) !default;

$text-darker: lighten(#847198, 2%);

$faint-shadow-color: color.adjust(#000000, $alpha: -0.6);

$border-radius: 8px;

@use '../variables' with (
  $success-green: #5fe43d,
  $error-red: #c9343b,
  $warning-red: #c96932,
  $gold-star: #e4ba3d,
  // Intentionally switched values
  $red-500: #b7253d,
  $red-600: #df405a,

  $simple-background-color: lighten($classic-base-color, 10%),
  $ui-base-color: $classic-base-color,
  $ui-base-lighter-color: lighten($classic-base-color, 10%),
  $ui-primary-color: $classic-primary-color,
  $ui-secondary-color: $classic-secondary-color,
  $ui-highlight-color: $blurple-500,
  $ui-button-color: #ffe9fc,
  $ui-button-background-color: $blurple-500,
  $ui-button-focus-background-color: $blurple-300,

  $ui-button-disabled-color: #a686a2,

  $ui-button-secondary-color: $blurple-500,
  $ui-button-secondary-border-color: $blurple-500,
  $ui-button-secondary-focus-color: #ffe9fc,

  $ui-button-tertiary-color: #d0adf6,
  $ui-button-tertiary-border-color: #d0adf6,
  $ui-button-tertiary-focus-background-color: #674e83,

  $primary-text-color: $classic-secondary-color,
  $darker-text-color: $text-darker,
  $dark-text-color: $text-darker,
  $action-button-color: $text-darker,
  // we don't do inverted backgrounds
  $inverted-text-color: $classic-secondary-color,
  $lighter-text-color: lighten($text-darker, 5%)
);
